import React from 'react';

import MultiPage from '../components/MultiPage';
import DownArrow from '../components/DownArrow';

import './p3.css';
import '../components/Infogrid.css';
import '../components/Typography.css';

function P3({
  faktaData,
  inskrivningar,
  faktaBostad,
  övrigt,
  driftkostnadData,
  kommentarDriftskostnad,
}: {
  faktaData: { titel: string; text: string; detaljer: string }[];
  inskrivningar: string;
  faktaBostad: { titel: string; text: string; detaljer: string }[];
  övrigt: string;
  driftkostnadData: { titel: string; text: string; detaljer: string }[];
  kommentarDriftskostnad: string;
}) {
  return (
    <MultiPage className="P3" renderMoreMarker={() => <DownArrow />}>
      <div className="InfoGrid">
        <div className="InfoGrid--item title">Fakta</div>
        {faktaData
          .filter(({ text }) => !!text)
          .map(({ titel, text, detaljer }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <div className="InfoGrid--item left">{titel}</div>
              <div className="InfoGrid--item right">
                <div>{text}</div>
                {detaljer && (
                  <div className="InfoGrid--item details">{detaljer}</div>
                )}
              </div>
            </React.Fragment>
          ))}
      </div>
      {inskrivningar && (
        <div>
          <div className="Typography--title">
            Inskrivningar/planbestämmelser
          </div>
          <div className="Typography--content">{inskrivningar}</div>
        </div>
      )}
      {faktaBostad.length > 0 && (
        <div>
          <div className="Typography--title">Byggnaden</div>
          <div className="InfoGrid">
            {faktaBostad
              .filter(({ text }) => !!text)
              .map(({ titel, text, detaljer }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={index}>
                  <div className="InfoGrid--item left">{titel}</div>
                  <div className="InfoGrid--item right">
                    <div>{text}</div>
                    {detaljer && (
                      <div className="InfoGrid--item details">{detaljer}</div>
                    )}
                  </div>
                </React.Fragment>
              ))}
          </div>
        </div>
      )}
      {övrigt && (
        <div>
          <div className="Typography--title">Övrigt</div>
          <div className="Typography--content">{övrigt}</div>
        </div>
      )}
      <div>
        <div className="Typography--title">Driftskostnad</div>
        <div className="InfoGrid">
          {driftkostnadData
            .filter(({ text }) => !!text)
            .map(({ titel, text, detaljer }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={index}>
                <div className="InfoGrid--item left">{titel}</div>
                <div className="InfoGrid--item right">
                  <div>{text}</div>
                  {detaljer && (
                    <div className="InfoGrid--item details">{detaljer}</div>
                  )}
                </div>
              </React.Fragment>
            ))}
        </div>
        {kommentarDriftskostnad && (
          <div className="Typography--title">Kommentar driftskostnad</div>
        )}
        {kommentarDriftskostnad && (
          <div className="Typography--content">{kommentarDriftskostnad}</div>
        )}
      </div>
    </MultiPage>
  );
}

export default P3;
