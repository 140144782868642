import { Box, Container, CssBaseline } from '@mui/material';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import './objektsbeskrivning.css';

import NoPrint from '../../components/NoPrint';
import { cost, number, percent } from '../../formatters';
import {
  HomeType,
  ObjektsbeskrivningHemInfoQuery,
  TenureType,
  useObjektsbeskrivningHemInfoQuery,
} from '../../generated/graphql';
import {
  Renderable,
  StorageContext,
  useEditableArray,
  useEditableBoolean,
  useEditableData,
  useEditableImagePosition,
  useMultiChoiceData,
} from '../../hooks/hooks';
import P1 from '../../pages/p1';
import P10 from '../../pages/p10';
import P2 from '../../pages/p2';
import P3 from '../../pages/p3';
import P5 from '../../pages/p5';
import P6 from '../../pages/p6';
import P7 from '../../pages/p7';
import P8 from '../../pages/p8';
import P9 from '../../pages/p9';

function Controls({ data }: { data: { [key: string]: Renderable } }) {
  return (
    <NoPrint>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {Object.values(data).map((entry, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>{entry.render()}</React.Fragment>
          ))}
        </Box>
      </Container>
    </NoPrint>
  );
}

function ObjektsbeskrivningWithData({
  home,
}: {
  home: ObjektsbeskrivningHemInfoQuery['home'];
}) {
  console.log(home.tenure);
  const commonData = {
    typ: useMultiChoiceData(
      'Gemensam Typ',
      useMemo(() => {
        switch (home.tenure) {
          case TenureType.GenericHouse:
          case TenureType.Freehold:
          case TenureType.Leasehold:
            return ['Villa', 'Lägenhet'];
          default:
            return ['Lägenhet', 'Villa'];
        }
      }, [home.tenure]),
    ),
  };

  const p1Data = {
    gata: useEditableData(
      'Sida 1 Gata',
      useMemo(() => home.address.streetAddress ?? '', [home]),
    ),
    storlekGata: useMultiChoiceData(
      'Sida 1 Storlek gata',
      useMemo(() => ['normal', 'mindre', 'jätteliten'], []),
    ),
    område: useEditableData(
      'Sida 1 Område',
      useMemo(
        () => `${home.area?.name ?? ''}, ${home.address.city ?? ''}`,
        [home],
      ),
    ),

    splash: useMultiChoiceData(
      'Sida 1 Splashbild',
      useMemo(
        () => [
          ...(home.images.map(
            image => `https://cdn.norban.se/images/${image.id}.jpg`,
          ) ?? []),
          '/defaults/1.jpg',
          '/defaults/2.jpg',
          '/defaults/3.jpg',
          '/defaults/4.jpg',
          '/defaults/5.jpg',
          '/defaults/6.jpg',
          '/defaults/7.jpg',
          '/defaults/8.jpg',
          '/defaults/9.jpg',
        ],
        [home],
      ),
      true,
    ),
    splashModifier: useEditableImagePosition('Sida 1 Splashbild ptz'),
  };

  const p2Data = {
    data: useEditableArray<{
      typFilter?: string;
      titel: string;
      text: string;
      detaljer: string;
    }>(
      'Sida 2 Data',
      { titel: '', text: '', detaljer: '' },
      useMemo(
        () =>
          [
            {
              titel: 'Pris',
              text: cost(home.askingPrice ?? 0),
              detaljer: '',
            },
            {
              titel: 'Rum',
              text: number(home.numberOfRooms ?? 0),
              detaljer: '',
            },
            {
              titel: 'Storlek',
              text: `${[home.livingArea, home.otherArea]
                .flatMap(n => (n ? [n] : []))
                .map(number)
                .join(' + ')} m²`,
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Tomtstorlek',
              text: `${number(home.plot.area ?? 0)} m²`,
              detaljer: '',
            },
            {
              typFilter: 'Lägenhet',
              titel: 'Avgift',
              text: `${cost(home.monthlyFee ?? 0)} / månad`,
              detaljer: home.monthlyFeeDescription ?? '',
            },
            {
              typFilter: 'Lägenhet',
              titel: 'Våning',
              text: home.floor
                ? `${[home.floor, home.building.floors]
                    .flatMap(n => (typeof n === 'number' ? [n] : []))
                    .join(' av ')} / ${home.elevator ? 'hiss' : 'ingen hiss'}`
                : '',
              detaljer: '',
            },
            {
              titel: 'Byggår',
              text: home.building.year ?? '',
              detaljer: '',
            },
            {
              titel: 'Typ',
              text: home.type
                ? {
                    [HomeType.Apartment]: 'Lägenhet',
                    [HomeType.Cottage]: 'Fritidshus',
                    [HomeType.House]: 'Villa',
                    [HomeType.TerraceHouse]: 'Kedjehus',
                    [HomeType.Townhouse]: 'Radhus',
                    [HomeType.TwinHouse]: 'Parhus',
                  }[home.type] ?? ''
                : '',

              detaljer: '',
            },
            // {
            //   titel: 'Övrig yta',
            //   text: [
            //     home.balcony ? ['balkong'] : [],
            //     home.patio ? ['uteplats'] : [],
            //     home.terrace ? ['terrass'] : [],
            //   ]
            //     .flat()
            //     .join(', ')
            //     .replace(/(\w)/, s => s.toUpperCase()),
            //   detaljer: '',
            // },
          ].filter(
            ({ typFilter }) =>
              !typFilter || typFilter.match(commonData.typ.data),
          ),
        [
          commonData.typ.data,
          home.askingPrice,
          home.building.floors,
          home.building.year,
          home.elevator,
          home.floor,
          home.livingArea,
          home.monthlyFee,
          home.monthlyFeeDescription,
          home.numberOfRooms,
          home.otherArea,
          home.plot.area,
          home.type,
        ],
      ),
    ),
    bostadsAnnonsUrl: useEditableData(
      'Sida 2 Bostadsannons URL',
      useMemo(() => `https://norban.se/vara-bostader/${home.id}`, [home]),
    ),
    mäklareNamnTele: useEditableData(
      'Sida 2 Märklare namn/tele',
      useMemo(
        () => `${home.realtor?.name ?? ''}\n${home.realtor?.phone ?? ''}`,
        [home],
      ),
    ),
    mäklareEmail: useEditableData(
      'Sida 2 Märklare email',
      useMemo(() => home.realtor?.email ?? '', [home]),
    ),
    mäklareBild: useEditableData(
      'Sida 2 Märklare bild',
      useMemo(
        () => `https://cdn.norban.se/images/${home.realtor?.imageId ?? ''}.jpg`,
        [home],
      ),
    ),
    mäklareBildModifier: useEditableImagePosition('Sida 2 Mäklare modifikator'),
    showBackup: useEditableBoolean(
      'Sida 2 Visa backup',
      useMemo(() => !!home.backup, [home]),
    ),
    backupNamnTele: useEditableData(
      'Sida 2 Backup namn/tele',
      useMemo(
        () => `${home.backup?.name ?? ''}, ${home.backup?.phone ?? ''}`,
        [home],
      ),
    ),
    backupEmail: useEditableData(
      'Sida 2 Backup email',
      useMemo(() => home.backup?.email ?? '', [home]),
    ),
    backupBild: useEditableData(
      'Sida 2 Backup bild',
      useMemo(
        () => `https://cdn.norban.se/images/${home.backup?.imageId ?? ''}.jpg`,
        [home],
      ),
    ),
    backupBildModifier: useEditableImagePosition('Sida 2 Backup modifikator'),
  };

  const operatingCost = (sum: number | undefined | null) =>
    sum ? cost(sum) : '';

  const p3Data = {
    faktaData: useEditableArray<{
      typFilter?: string;
      titel: string;
      text: string;
      detaljer: string;
    }>(
      'Sida 3 Fakta data',
      { titel: '', text: '', detaljer: '' },
      useMemo(
        () =>
          [
            {
              typFilter: 'Lägenhet',
              titel: 'Lägenhetsnummer',
              text: `Fyll i från Vitec`,
              detaljer: '',
            },
            {
              typFilter: 'Lägenhet',
              titel: 'Upplåtelseform',
              text: home.tenure
                ? {
                    [TenureType.HousingCooperative]: 'Bostadsrätt',
                    [TenureType.GenericHouse]: 'Villa',
                    [TenureType.Freehold]: 'Friköpt',
                    [TenureType.Leasehold]: 'Tomträtt',
                    [TenureType.ShareInHousingAssociation]:
                      'Andel i bostadsförening',
                    [TenureType.Condominium]: 'Andelsrätt',
                    [TenureType.Cottage]: 'Fritidshus',
                    [TenureType.Project]: 'Projekt',
                  }[home.tenure]
                : '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Ägandeform',
              text: home.tenure
                ? {
                    [TenureType.HousingCooperative]: 'Bostadsrätt',
                    [TenureType.GenericHouse]: 'Villa',
                    [TenureType.Freehold]: 'Friköpt',
                    [TenureType.Leasehold]: 'Tomträtt',
                    [TenureType.ShareInHousingAssociation]:
                      'Andel i bostadsförening',
                    [TenureType.Condominium]: 'Andelsrätt',
                    [TenureType.Cottage]: 'Fritidshus',
                    [TenureType.Project]: 'Projekt',
                  }[home.tenure]
                : '',
              detaljer: '',
            },
            {
              titel: 'Fastighetsbeteckning',
              text: home.building.propertyDesignation ?? ``,
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Pantbrev',
              text: home.mortgages
                ? cost(
                    home.mortgages
                      .map(m => m.amount ?? 0)
                      .reduce((a, b) => a + b, 0),
                  )
                : '',
              detaljer: '',
            },
            {
              typFilter: 'Lägenhet',
              titel: 'Inre reparationsfond',
              text: cost(home.association.repairFund ?? 0),
              detaljer: '',
            },
            {
              typFilter: 'Lägenhet',
              titel: 'Andel i föreningen',
              text: percent(home.association.percentageOfAssociation ?? 0),
              detaljer: '',
            },
            {
              typFilter: 'Lägenhet',
              titel: 'Andel av årsavgift',
              text: percent(home.association.percentageOfAnnualFee ?? 0),
              detaljer: '',
            },
            {
              typFilter: 'Lägenhet',
              titel: 'Pantsatt',
              text: (() => {
                if (typeof home.pawned !== 'boolean') {
                  return '';
                }

                return home.pawned ? 'Ja' : 'Nej';
              })(),
              detaljer: '',
            },
            {
              typFilter: 'Lägenhet',
              titel: 'Indirekt nettoskuld',
              text: cost(home.association.indirectNetDebt ?? 0),
              detaljer: home.association.indirectNetDebtComment ?? '',
            },
            {
              titel: 'Energideklaration',
              text: home.energyDeclaration.date
                ? `Utförd ${new Date(
                    home.energyDeclaration.date,
                  ).toLocaleDateString('sv-SE', {})}`
                : 'Ej utförd',
              detaljer: '',
            },
            {
              titel: 'Energiprestanda',
              text: home.energyDeclaration.energyPerformance
                ? `${number(
                    Number.parseInt(
                      home.energyDeclaration.energyPerformance,
                      10,
                    ),
                  )} kWh/m² och år`
                : '',
              detaljer: '',
            },
            {
              titel: 'Energiklass',
              text:
                home.energyDeclaration.energyClass?.replace('None', '') ?? '',
              detaljer: '',
            },
            {
              titel: 'Källa boarea',
              text: home.areaSource ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Taxeringkod',
              text: home.assessment.typeCode ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Taxeringsår',
              text: `${home.assessment.yearOfAssessment}` ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Taxeringsvärde byggnad',
              text: cost(home.assessment.buildingValue ?? 0),
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Taxeringsvärde mark',
              text: cost(home.assessment.landValue ?? 0),
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Summa taxeringsvärde',
              text: cost(home.assessment.totalValue ?? 0),
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Skatt/avgift',
              text: home.assessment.fee
                ? `${cost(Number(home.assessment.fee))} / år`
                : '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Tomträttsavgäld',
              text: home.leasehold.fee
                ? `${cost(Number(home.leasehold.fee))} / år`
                : '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Tomträtt löptid',
              text: home.leasehold.expiryDate
                ? new Date(home.leasehold.expiryDate).toLocaleDateString(
                    'sv-SE',
                    {},
                  )
                : '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Fullvärdesförsäkrad',
              text: 'Fyll i från Vitec',
              detaljer: '',
            },
          ].filter(
            ({ typFilter }) =>
              !typFilter || typFilter.match(commonData.typ.data),
          ),
        [
          commonData.typ.data,
          home.areaSource,
          home.assessment.buildingValue,
          home.assessment.fee,
          home.assessment.landValue,
          home.assessment.totalValue,
          home.assessment.typeCode,
          home.assessment.yearOfAssessment,
          home.association.indirectNetDebt,
          home.association.indirectNetDebtComment,
          home.association.percentageOfAnnualFee,
          home.association.percentageOfAssociation,
          home.association.repairFund,
          home.building.propertyDesignation,
          home.energyDeclaration.date,
          home.energyDeclaration.energyClass,
          home.energyDeclaration.energyPerformance,
          home.leasehold.expiryDate,
          home.leasehold.fee,
          home.mortgages,
          home.pawned,
          home.tenure,
        ],
      ),
    ),
    inskrivningar: useEditableData(
      'Sida 3 Inskrivningar',
      useMemo(
        () => (commonData.typ.data === 'Villa' ? 'KOPIERA IN' : ''),
        [commonData.typ],
      ),
    ),
    faktaBostad: useEditableArray<{
      typFilter?: string;
      titel: string;
      text: string;
      detaljer: string;
    }>(
      'Sida 3 Fakta Bostaden',
      { titel: '', text: '', detaljer: '' },
      useMemo(
        () =>
          [
            {
              typFilter: 'Villa',
              titel: 'Byggnadstyp',
              text: home.building.type ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Fönster',
              text: home.building.windows ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Uppvärming',
              text: home.building.heating ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Ventilation',
              text: home.building.ventilation ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Fasad',
              text: home.building.facade ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Tak',
              text: home.building.roof ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Stomme',
              text: home.building.frame ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Bjälklag',
              text: home.building.beam ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Grundläggning',
              text: home.building.foundation ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Grundmur',
              text: home.building.foundationWall ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'Utvändigt plåtarbete',
              text: home.building.externalSheetMetalWork ?? '',
              detaljer: '',
            },
            {
              typFilter: 'Villa',
              titel: 'V/A',
              text: home.waterAndSewage.info ?? '',
              detaljer: '',
            },
          ].filter(
            ({ typFilter }) =>
              !typFilter || typFilter.match(commonData.typ.data),
          ),
        [
          commonData.typ.data,
          home.building.beam,
          home.building.externalSheetMetalWork,
          home.building.facade,
          home.building.foundation,
          home.building.foundationWall,
          home.building.frame,
          home.building.heating,
          home.building.roof,
          home.building.type,
          home.building.ventilation,
          home.building.windows,
          home.waterAndSewage.info,
        ],
      ),
    ),
    övrigt: useEditableData(
      'Sida 4 Övrigt',
      useMemo(() => home.description.other ?? '', [home]),
    ),
    driftkostnadData: useEditableArray<{
      titel: string;
      text: string;
      detaljer: string;
    }>(
      'Sida 2 Driftkostnad data',
      { titel: '', text: '', detaljer: '' },
      useMemo(
        () => [
          {
            titel: 'Antal personer',
            text: home.operatingCost.residents
              ? `${home.operatingCost.residents ?? 0} st`
              : '',
            detaljer: '',
          },
          {
            titel: 'Hushållsel',
            text: operatingCost(home.operatingCost.electricity),
            detaljer: '',
          },
          {
            titel: 'Försäkring',
            text: operatingCost(home.operatingCost.insurance),
            detaljer: '',
          },
          {
            titel: 'Sotning',
            text: operatingCost(home.operatingCost.chimneySweeping),
            detaljer: '',
          },
          {
            titel: 'Vatten/avlopp',
            text: operatingCost(home.operatingCost.waterAndSewage),
            detaljer: '',
          },
          {
            titel: 'Uppvärmning',
            text: operatingCost(home.operatingCost.heating),
            detaljer: '',
          },
          {
            titel: 'Renhållning',
            text: operatingCost(home.operatingCost.sanitation),
            detaljer: '',
          },
          {
            titel: 'Samfällighet',
            text: operatingCost(home.operatingCost.roadCommunity),
            detaljer: '',
          },
          {
            titel: 'Övrigt',
            text: operatingCost(home.operatingCost.other),
            detaljer: '',
          },
          {
            // TODO: title?
            titel: 'Vägavgift',
            text: operatingCost(home.operatingCost.roadCharge),
            detaljer: '',
          },

          {
            titel: 'Underhåll',
            text: operatingCost(home.operatingCost.maintenance),
            detaljer: '',
          },

          {
            titel: 'Totalt',
            text: `${cost(home.operatingCost.total ?? 0)} / år`,
            detaljer: home.assessment.fee
              ? `utöver detta tillkommer kostnad för fastighetsavgift/skatt ${cost(
                  home.assessment.fee ?? 0,
                )} / år`
              : '',
          },
        ],
        [
          home.assessment.fee,
          home.operatingCost.chimneySweeping,
          home.operatingCost.electricity,
          home.operatingCost.heating,
          home.operatingCost.insurance,
          home.operatingCost.maintenance,
          home.operatingCost.other,
          home.operatingCost.residents,
          home.operatingCost.roadCharge,
          home.operatingCost.roadCommunity,
          home.operatingCost.sanitation,
          home.operatingCost.total,
          home.operatingCost.waterAndSewage,
        ],
      ),
    ),
    kommentarDriftskostnad: useEditableData(
      'Sida 4 Kommentar driftskostnad',
      useMemo(() => home.operatingCost.commentary ?? '', [home]),
    ),
  };

  const p5Data = {
    beskrivning: useEditableData(
      'Sida 5 Beskrivning',
      useMemo(() => home.description.short ?? '', [home]),
    ),

    data: useEditableArray(
      'Sida 5 Data',
      { titel: '', innehåll: '' },
      useMemo(
        () =>
          home.rooms.map(room => ({
            titel: room.heading ?? '',
            innehåll: room.text ?? '',
          })),
        [home.rooms],
      ),
    ),
  };

  const p6Data = {
    harFörening: useEditableBoolean(
      'Sida 6 Har förening',
      useMemo(() => commonData.typ.data === 'Lägenhet', [commonData.typ.data]),
    ),
    föreningsnamn: useEditableData(
      'Sida 6 Föreningsnamn',
      useMemo(() => home.association.name ?? '', [home]),
    ),
    föreningsinfo: useEditableData(
      'Sida 6 Föreningsinfo',
      useMemo(() => home.association.generalDescription ?? '', [home]),
    ),
  };

  const p7Data = {
    köparInformation: useEditableData(
      'Sida 7 Köparinformation',

      useMemo(() => {
        switch (commonData.typ.data) {
          case 'Lägenhet':
            return `Köparen uppmanas att noggrant undersöka bostaden före köp.
En bostadsrätt/andelsrätt säljs i befintligt skick. Som köpare kan man inte åberopa fel i bostaden efter överlåtelsen, om felet hade varit upptäckbart vid en noggrann undersökning inför köpet. Säljaren är inte ansvarig för fel eller brister som köparen borde ha räknat med eller kunnat förvänta sig med hänsyn till bostadens ålder, pris och skick. Upptäcker köparen tecken på fel eller om det finns någon anledning till misstankar om fel i bostaden, utökas köparens undersökningsplikt. Som köpare bör man anlita en professionell besiktningsman om man är osäker.
Areauppgiften i annonsen kan av olika skäl vara felaktig, bland annat beroende på äldre uppmätningsnormer. Den köpare som anser att den exakta arean är av betydelse, bör låta mäta upp bostaden före köpet.
Juridisk information finns att läsa i dokumentet Bra att veta!
Föreningens årsredovisning, stadgar och ev energideklaration finns i bostadsannonsen på webben.
I föreningens stadgar finns information om bostadsrätt/andelsrättssinnehavarens respektive föreningens rättigheter och skyldigheter samt ansvarsfördelningen.
Kontakta mäklaren för en personlig boendekostnadskalkyl.`;
          case 'Villa':
            return `Köparen uppmanas att noggrant undersöka fastigheten och tillhörande byggnad före köp.
Som köpare har man undersökningsplikt, dvs en skyldighet att noggrant undersöka fastigheten. Om en normalt kunnig köpare skulle ha kunnat upptäcka fel vid en tillräckligt noggrann undersökning, är felet upptäckbart. Som köpare kan man inte åberopa fel som man borde ha förväntat sig med hänsyn till fastighetens ålder, pris, skick och användning. Upptäcker köparen tecken på fel eller om det finns någon anledning till misstankar om fel, utökas köparens undersökningsplikt och gör köparen skyldig att utreda den bakomliggande orsaken till felet och omfattningen på felet. Köparen har kvar sin undersökningsplikt även om säljaren kan presentera ett besiktningsutlåtande. Säljaren ansvarar inte för fel som är upptäckbara vid köpet utan endast s. k. dolda fel. Som köpare bör man anlita en professionell besiktningsman om man är osäker. Tänk dock på att besiktningsmän normalt inte undersöker eldstäder, el, VA, VVS, sidobyggnader m.m. Även detta ingår i köparens undersökningsplikt. 
Källa: Jordabalk (1970:994). Areauppgiften i annonsen kan av olika skäl vara felaktig, bland annat beroende på äldre uppmätningsnormer. Den köpare som anser att den exakta arean är av betydelse, bör låta mäta upp bostaden före köpet.
Juridisk information finns att läsa i dokumentet Bra att veta i bostadsannonsen på webben. Kontakta mäklaren för en personlig boendekostnadskalkyl.`;
          default:
            return '';
        }
      }, [commonData.typ.data]),
    ),
    arvodeInfoPreMarket: useEditableData(
      'Sida 7 Arvode info Pre Market',
      'Vid köp av bostad via Norbans Pre-Market™ tillkommer ett köparvode för dig som köpare på 22 500 kr.',
    ),
  };
  const p8Data = {
    bild1: useMultiChoiceData(
      'Sida 8 Bild 1',
      useMemo(
        () =>
          home.images.map(
            image => `https://cdn.norban.se/images/${image.id}.jpg`,
          ) ?? [],
        [home],
      ),
      true,
    ),
    bild1Modifier: useEditableImagePosition('Sida 8 Bild 1 modifikator'),
    bild2: useMultiChoiceData(
      'Sida 8 Bild 2',
      useMemo(
        () =>
          home.images.map(
            image => `https://cdn.norban.se/images/${image.id}.jpg`,
          ) ?? [],

        [home],
      ),
      true,
    ),
    bild2Modifier: useEditableImagePosition('Sida 8 Bild 2 modifikator'),
    bild3: useMultiChoiceData(
      'Sida 8 Bild 3',
      useMemo(
        () =>
          home.images.map(
            image => `https://cdn.norban.se/images/${image.id}.jpg`,
          ) ?? [],
        [home],
      ),
      true,
    ),
    bild3Modifier: useEditableImagePosition('Sida 8 Bild 3 modifikator'),
  };
  const p9Data = {
    planritningar: useEditableArray<{ url: string }>(
      'Sida 9 Planritningar',
      { url: '' },
      home.images
        .filter(image => image.isFloorPlan)
        .map(planritning => ({
          url: `https://cdn.norban.se/images/${planritning.id ?? ''}.jpg`,
        })),
    ),
    måstGodkännas: useEditableBoolean(
      'Sida 9 Ändring av planlösning måste godkännas',
      useMemo(() => commonData.typ.data === 'Lägenhet', [commonData.typ.data]),
    ),
  };

  const p10Data = {};

  return (
    <div className="Objektbeskrivning--container">
      <NoPrint />
      <Controls data={commonData} />
      <P1
        gata={p1Data.gata.data}
        storlekGata={p1Data.storlekGata.data}
        område={p1Data.område.data}
        splash={p1Data.splash.data}
        splashModifier={p1Data.splashModifier.data}
      />
      <Controls data={p1Data} />
      <P2
        data={p2Data.data.data}
        backupBild={p2Data.backupBild.data}
        backupBildModifier={p2Data.backupBildModifier.data}
        backupEmail={p2Data.backupEmail.data}
        backupNamnTele={p2Data.backupNamnTele.data}
        bostadsAnnonsUrl={p2Data.bostadsAnnonsUrl.data}
        mäklareBild={p2Data.mäklareBild.data}
        mäklareBildModifier={p2Data.mäklareBildModifier.data}
        mäklareEmail={p2Data.mäklareEmail.data}
        mäklareNamnTele={p2Data.mäklareNamnTele.data}
        showBackup={p2Data.showBackup.data}
      />
      <Controls data={p2Data} />
      <P3
        faktaData={p3Data.faktaData.data}
        inskrivningar={p3Data.inskrivningar.data}
        faktaBostad={p3Data.faktaBostad.data}
        övrigt={p3Data.övrigt.data}
        driftkostnadData={p3Data.driftkostnadData.data}
        kommentarDriftskostnad={p3Data.kommentarDriftskostnad.data}
      />
      <Controls data={p3Data} />
      <P5 beskrivning={p5Data.beskrivning.data} data={p5Data.data.data} />
      <Controls data={p5Data} />
      <P6
        harFörening={p6Data.harFörening.data}
        föreningsnamn={p6Data.föreningsnamn.data}
        föreningsinfo={p6Data.föreningsinfo.data}
      />
      <Controls data={p6Data} />
      <P7
        köparInformation={p7Data.köparInformation.data}
        arvodeInfoPreMarket={p7Data.arvodeInfoPreMarket.data}
      />
      <Controls data={p7Data} />
      <P8
        bild1={p8Data.bild1.data}
        bild1Modifier={p8Data.bild1Modifier.data}
        bild2={p8Data.bild2.data}
        bild2Modifier={p8Data.bild2Modifier.data}
        bild3={p8Data.bild3.data}
        bild3Modifier={p8Data.bild3Modifier.data}
      />
      <Controls data={p8Data} />
      <div>
        {p9Data.planritningar.data.map(planritning => (
          <P9
            key={planritning.url}
            planritning={planritning.url}
            måstGodkännas={p9Data.måstGodkännas.data}
          />
        ))}
      </div>
      <Controls data={p9Data} />
      <P10 />
      <Controls data={p10Data} />
    </div>
  );
}

function Objektsbeskrivning() {
  const params = useParams();
  const { data, loading } = useObjektsbeskrivningHemInfoQuery({
    variables: { id: params.homeId || '' },
    skip: !params.homeId,
  });
  const storageKey = useMemo(() => params.homeId ?? '', [params.homeId]);

  if (loading || !data) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className="Objektbeskrivning--loading-title">
            {loading ? 'Loading...' : 'Loading failed!'}
          </div>
        </Box>
      </Container>
    );
  }

  return (
    <StorageContext.Provider value={storageKey}>
      <ObjektsbeskrivningWithData home={data?.home} />
    </StorageContext.Provider>
  );
}

export default Objektsbeskrivning;
