import React from 'react';

import { ReactComponent as DownArrowIcon } from './DownArrow.svg';
import './DownArrow.css';

export default function DownArrow() {
  return (
    <div className="DownArrow--container">
      <DownArrowIcon width="16pt" height="16pt" />
    </div>
  );
}
