import React from 'react';

import Page from '../components/Page';

import { ReactComponent as Logo } from './p1/logo.svg';

import './p10.css';
import '../components/Typography.css';

function P10() {
  return (
    <Page className="P10">
      <div className="P10--logo">
        <Logo width="120pt" height="27pt" />
      </div>
      <span className="P10--info">
        Vi finns i Stockholm, Göteborg, Malmö och Lund.
      </span>
      <span className="P10--info">
        Växel: 010 - 750 06 00 |{' '}
        <span className="P10--info highlight">norban.se</span>
      </span>
    </Page>
  );
}

export default P10;
