import { Status, Wrapper } from '@googlemaps/react-wrapper';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { useMapAllHomesQuery } from '../../generated/graphql';

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;

  throw new Error();
};

function MyMapComponent({
  center,
  zoom,
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);

  const { data } = useMapAllHomesQuery();

  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    // eslint-disable-next-line no-new
    setMap(
      new window.google.maps.Map(ref.current, {
        center,
        zoom,
      }),
    );
  }, [center, zoom]);

  useEffect(() => {
    if (data === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    const markers = data.publicHomes.map(
      home =>
        new google.maps.Marker({
          position: {
            lat:
              home.coordinate?.latitude ??
              home.undisclosedAddress?.latitude ??
              0,
            lng:
              home.coordinate?.longitude ??
              home.undisclosedAddress?.longitude ??
              0,
          },
          map,
        }),
    );

    return () => markers.forEach(marker => marker.setMap(null));
  }, [data, map]);

  return <div style={{ height: '100vh' }} ref={ref} id="map" />;
}

function Map() {
  const center = { lat: -34.397, lng: 150.644 };
  const zoom = 4;

  return (
    <Wrapper apiKey="AIzaSyACsMMykK1HMemhUnUJu31Gw9PU4iY0Eis" render={render}>
      <MyMapComponent center={center} zoom={zoom} />
    </Wrapper>
  );
}

export default Map;
