import React from 'react';

import Page from '../components/Page';

import './p7.css';
import '../components/Typography.css';

function P7({
  köparInformation,
  arvodeInfoPreMarket,
}: {
  köparInformation: string;
  arvodeInfoPreMarket: string;
}) {
  return (
    <Page className="P7">
      <div className="Typography--header">Köparinformation</div>
      {köparInformation.split('\n').map((line, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={`Typography--smallcontent ${
            index === 0 ? 'underlined' : ''
          }`}
        >
          {line}
        </div>
      ))}
      <div className="P7--markets-container">
        <div className="P7--market-container">
          <div className="P7--market-dot green" />
          <div className="P7--market-title">Pre-Market™</div>
          <div className="P7--market-info">{arvodeInfoPreMarket}</div>
        </div>
      </div>
    </Page>
  );
}

export default P7;
