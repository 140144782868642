import React from 'react';

import Page from '../components/Page';
import { ImageModifier } from '../hooks/hooks';

import { ReactComponent as LinkIcon } from './p2/external-link.svg';

import './p2.css';
import '../components/Infogrid.css';
import '../components/Typography.css';

function P2({
  data,
  bostadsAnnonsUrl,
  mäklareNamnTele,
  mäklareEmail,
  mäklareBild,
  mäklareBildModifier,
  showBackup,
  backupNamnTele,
  backupEmail,
  backupBild,
  backupBildModifier,
}: {
  data: { titel: string; text: string; detaljer: string }[];
  bostadsAnnonsUrl: string;
  mäklareNamnTele: string;
  mäklareEmail: string;
  mäklareBild: string;
  mäklareBildModifier: ImageModifier;
  showBackup: boolean;
  backupNamnTele: string;
  backupEmail: string;
  backupBild: string;
  backupBildModifier: ImageModifier;
}) {
  return (
    <Page className="P2">
      <div className="InfoGrid">
        <div className="InfoGrid--item title">Snabbinfo.</div>
        {data
          .filter(({ text }) => text)
          .map(({ titel, text, detaljer }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <div className="InfoGrid--item left">{titel}</div>
              <div className="InfoGrid--item right">
                <div>{text}</div>
                {detaljer && (
                  <div className="InfoGrid--item details">{detaljer}</div>
                )}
              </div>
            </React.Fragment>
          ))}
      </div>
      <a href={bostadsAnnonsUrl} className="P2--link-container">
        <div className="P2--link">
          <div className="P2--link-text">Bostadsannons</div>
          <div className="P2--link-icon">
            <LinkIcon height="11pt" width="11pt" />
          </div>
        </div>
      </a>
      <div className="P2--expander" />
      <div className="P2--contact">
        <div className="P2--contact-photo-container">
          <div className="P2--contact-photo">
            <img alt="mäklare" src={mäklareBild} {...mäklareBildModifier} />
          </div>
        </div>
        <div className="P2--contact-info-container">
          <div className="P2--contact-info-title">Ansvarig mäklare</div>
          <pre className="P2--contact-info-name">{mäklareNamnTele}</pre>
          <a
            href={`mailto: ${mäklareEmail}`}
            className="P2--contact-info-title"
          >
            {mäklareEmail}
          </a>
        </div>
      </div>
      {showBackup && (
        <div className="P2--backup-contact">
          <div className="P2--contact-photo-container">
            <div className="P2--contact-photo">
              <img alt="mäklare" src={backupBild} {...backupBildModifier} />
            </div>
          </div>
          <div className="P2--contact-info-container">
            <div className="P2--contact-info-title">Extra kontakt</div>
            <pre className="P2--contact-info-name">{backupNamnTele}</pre>
            <a
              href={`mailto: ${backupEmail}`}
              className="P2--contact-info-title"
            >
              {backupEmail}
            </a>
          </div>
        </div>
      )}
    </Page>
  );
}

export default P2;
