import React from 'react';

import Page from '../components/Page';

import '../components/Typography.css';
import './p9.css';

function P9({
  planritning,
  måstGodkännas,
}: {
  planritning: string;
  måstGodkännas: boolean;
}) {
  return (
    <Page className="P9">
      <div className="Typography--header">Planritning</div>
      <div className="P9--plaritning-container">
        <img alt="" src={planritning} style={{ objectFit: 'contain' }} />
      </div>
      {måstGodkännas && (
        <span className="Typography--smallcontent">
          Observera att en förändring av nuvarande planlösning{' '}
          <span className="underlined">
            alltid måste godkännas av föreningen
          </span>{' '}
          innan genomförande.
        </span>
      )}
    </Page>
  );
}

export default P9;
