import React from 'react';
import { Routes, Route } from 'react-router-dom';

import AppleMaps from './applemaps';
import Authentication from './authentication';
import Map from './map';

function App() {
  return (
    <Routes>
      <Route index element={<Authentication />} />
      <Route path="map/" element={<Map />} />
      <Route path="apple-maps/" element={<AppleMaps />} />
    </Routes>
  );
}

export default App;
