import React, {
  Children,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Page from './Page';

export default function MultiPage({
  children,
  className,
  renderMoreMarker,
}: PropsWithChildren<{
  className: string;
  renderMoreMarker: () => React.ReactNode;
}>) {
  const [done, setDone] = useState(true);
  const [arm, setArm] = useState(true);
  const [sliceLength, setSliceLength] = useState(Children.count(children));
  useEffect(() => {
    setSliceLength(Children.count(children));
  }, [children]);
  const hasMore = useMemo(
    () => sliceLength < Children.count(children),
    [children, sliceLength],
  );
  const myChildren = useMemo(
    () => Children.toArray(children).slice(0, sliceLength),
    [children, sliceLength],
  );
  const nextChildren = useMemo(
    () => Children.toArray(children).slice(sliceLength),
    [children, sliceLength],
  );

  return (
    <div>
      <Page
        className={className}
        renderWithOverFlow={overflow => {
          if (arm && overflow && sliceLength > 1) {
            setTimeout(() => {
              setDone(false);
              setArm(false);
              setSliceLength(sliceLength - 1);
            });

            setTimeout(() => {
              setArm(true);
            }, 25);
          }

          if (!overflow) {
            setTimeout(() => {
              setDone(true);
            });
          }

          const failed = sliceLength === 1 && overflow && arm;

          return (
            <>
              {hasMore && renderMoreMarker()}
              {failed && (
                <div>
                  Misslyckades med att sidbryta nedanstående stycke. Får inte
                  plats på 1 sida!
                </div>
              )}
              {myChildren}
            </>
          );
        }}
      />
      {done && hasMore && (
        <MultiPage className={className} renderMoreMarker={renderMoreMarker}>
          {nextChildren}
        </MultiPage>
      )}
    </div>
  );
}
