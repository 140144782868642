import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Alert } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import React, { useCallback, useEffect, useState } from 'react';

import useSession from '../../useSession';

export default function SignIn() {
  const [authenticationError, setAuthenticationError] = useState(false);
  const [antiPhishingCode, setAntiPhishingCode] = useState<
    string | undefined
  >();

  const { loginEmail, refreshJwt } = useSession();

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);

      try {
        const result = await loginEmail({
          email: data.get('email')?.toString() ?? '',
        });

        setAntiPhishingCode(
          result.mutationResult?.loginEmailResult
            ?.emailChallengeAntiPhishingCode ?? undefined,
        );
      } catch (error) {
        setAuthenticationError(true);
      }
    },
    [loginEmail],
  );

  useEffect(() => {
    if (!antiPhishingCode) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    const interval = setInterval(async () => {
      // Refresh the JWT periodically until the user has clicked the link
      const jwt = await refreshJwt();
      if (jwt) {
        window.location.href = '/authenticated';
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [antiPhishingCode, refreshJwt]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        {antiPhishingCode &&
          `Kolla din email, du kan behöva skriva in koden: ${antiPhishingCode}.`}
        {!antiPhishingCode && (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Logga in
            </Button>
            {authenticationError && (
              <Alert severity="warning">Fel användarnamn/lösenord!</Alert>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
}
