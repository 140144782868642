import React, { useMemo } from 'react';
import { Map, Marker } from 'react-mapkit';

import { useMapAllHomesQuery } from '../../generated/graphql';

const token =
  'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Iks4N1VVTDk5NVkifQ.eyJpYXQiOjE2NTQxNDkxNDIuNTM2LCJpc3MiOiI4Q0xKOU5KWjhTIn0.gk6sWu3JPMsgZBn6GWqihn6El-vyT-bcecnoCW6ITGJR1uGJeZRWKiYGKfztRtxwCWLfdvtl3rg0orLiM7DFJA';
function AppleMaps() {
  const { data } = useMapAllHomesQuery();

  const markers = useMemo(
    () =>
      data?.publicHomes
        .map(home => ({
          id: home.id,
          latitude:
            home.coordinate?.latitude ?? home.undisclosedAddress?.latitude ?? 0,
          longitude:
            home.coordinate?.longitude ??
            home.undisclosedAddress?.longitude ??
            0,
        }))
        .sort((c1, c2) => c2.latitude - c1.latitude)
        .map(coordinate => (
          <Marker
            key={coordinate.id}
            latitude={coordinate.latitude}
            longitude={coordinate.longitude}
          />
        )),
    [data?.publicHomes],
  );

  return (
    <div style={{ width: '1000px', height: '1000px' }}>
      <Map
        tokenOrCallback={token}
        region={{
          latitude: 47.6754,
          longitude: -122.2084,
          latitudeSpan: 0.006,
          longitudeSpan: 0.006,
        }}
      >
        {markers}
      </Map>
    </div>
  );
}

export default AppleMaps;
