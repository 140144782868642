import React from 'react';

import Page from '../components/Page';
import { ImageModifier } from '../hooks/hooks';

import './p8.css';
import '../components/Typography.css';

function P8({
  bild1,
  bild1Modifier,
  bild2,
  bild2Modifier,
  bild3,
  bild3Modifier,
}: {
  bild1: string;
  bild1Modifier: ImageModifier;
  bild2: string;
  bild2Modifier: ImageModifier;
  bild3: string;
  bild3Modifier: ImageModifier;
}) {
  return (
    <Page className="P8">
      <div className="Typography--header">Foton</div>
      <div className="P8--foton-container">
        <div className="P8--foto-landscape">
          <img alt="1" src={bild1} {...bild1Modifier} />
        </div>
        <div className="P8--foto-portrait">
          <img alt="2" src={bild2} {...bild2Modifier} />
        </div>
        <div className="P8--foto-portrait">
          <img alt="3" src={bild3} {...bild3Modifier} />
        </div>
      </div>
    </Page>
  );
}

export default P8;
