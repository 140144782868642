import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import {
  Alert,
  Autocomplete,
  createFilterOptions,
  TextField,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useCallback, useMemo, useState } from 'react';

import { useSelectHomeAllHomesQuery } from '../../generated/graphql';

import './selecthome.css';

export default function SelectHome({
  onHomeSelected,
}: {
  onHomeSelected: (id: string) => void;
}) {
  const { data, loading } = useSelectHomeAllHomesQuery();

  const homes = useMemo(() => data?.homes ?? [], [data?.homes]);

  const [value, setValue] = useState<typeof homes[0] | null>(null);
  const [selectHomeError, setSelectHomeError] = useState(false);

  const onSelectHome = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const v = value?.id;
      if (v === undefined || v === null) {
        setSelectHomeError(true);
        return;
      }

      onHomeSelected(v);
    },
    [onHomeSelected, value],
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <HouseOutlinedIcon />
        </Avatar>
        <Box component="form" onSubmit={onSelectHome} noValidate sx={{ mt: 1 }}>
          <Autocomplete
            disablePortal
            loading={loading}
            value={value}
            onChange={(_, newValue) => {
              setValue(newValue);
            }}
            id="combo-box-homes"
            filterOptions={createFilterOptions({
              stringify: home =>
                `${home.id} ${home.address.city}, ${home.address.streetAddress}`,
            })}
            options={homes}
            getOptionLabel={home =>
              `${home.address.city}, ${home.address.streetAddress} [${home.id}]`
            }
            renderOption={(props, home) => (
              <li {...props} key={home.id}>
                <span className="SelectHome-item-address">{`${home.address.city}, ${home.address.streetAddress}`}</span>
                <span>{home.id}</span>
              </li>
            )}
            sx={{ width: 300 }}
            renderInput={params => <TextField {...params} label="Hem" />}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Välj hem
          </Button>
          {selectHomeError && (
            <Alert severity="warning">Du måste välja ett hem!</Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
}
