import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useMemo, useRef } from 'react';

import { BACKEND_URI } from './config';
import useSession from './useSession';

export default function useBackendClient() {
  const client = useRef(
    new ApolloClient({
      link: ApolloLink.empty(),
      cache: new InMemoryCache(),
      name: 'backoffice',
    }),
  );

  const { jwt, refreshJwt } = useSession();

  const httpLink = useMemo(
    () =>
      new HttpLink({
        uri: BACKEND_URI,
        headers: {
          'apollo-require-preflight': true,
        },
      }),
    [],
  );

  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => ({
        headers: {
          ...headers,
          authorization: jwt ? `Bearer ${jwt}` : '',
          // Avoid cached results from the backend
          Pragma: 'no-cache',
          'Cache-Control': 'no-cache',
        },
      })),
    [jwt],
  );

  const badTokenLink = useMemo(
    () =>
      onError(({ graphQLErrors, operation, forward }) => {
        if (
          !graphQLErrors?.some(
            error => error.extensions?.code === 'UNAUTHENTICATED',
          )
        ) {
          return;
        }

        // Based on https://community.apollographql.com/t/refreshing-access-and-refresh-tokens-via-apollo-in-react/1440/5
        // eslint-disable-next-line consistent-return, @typescript-eslint/no-empty-function
        return fromPromise(refreshJwt().catch(() => {})).flatMap(newToken => {
          operation.setContext({
            headers: {
              ...operation.getContext().headers,
              authorization: newToken ? `Bearer ${newToken}` : undefined,
            },
          });

          return forward(operation);
        });
      }),
    [refreshJwt],
  );

  const link = useMemo(
    () => ApolloLink.from([authLink, badTokenLink, httpLink]),
    [authLink, badTokenLink, httpLink],
  );

  useMemo(() => {
    client.current.setLink(link);
  }, [link]);

  return client.current;
}
