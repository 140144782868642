import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import Objektsbeskrivning from './objektsbeskrivning';
import SelectHome from './selecthome';

function App() {
  const navigate = useNavigate();
  return (
    <Routes>
      <Route
        index
        element={
          <SelectHome
            onHomeSelected={(homeId: string) => {
              navigate(`objektsbeskrivning/${homeId}`);
            }}
          />
        }
      />
      <Route
        path="objektsbeskrivning/:homeId"
        element={<Objektsbeskrivning />}
      />
    </Routes>
  );
}

export default App;
