/* eslint-disable no-underscore-dangle */
declare global {
  interface Window {
    _env_: {
      WEB_URI: string;
      BACKEND_URI: string;
    };
  }
}

let env = {
  WEB_URI: '',
  BACKEND_URI: '',
};

if (typeof window !== 'undefined') {
  env = {
    WEB_URI: window._env_.WEB_URI,
    BACKEND_URI: window._env_.BACKEND_URI,
  };
}

export const { WEB_URI, BACKEND_URI } = env;
