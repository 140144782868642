import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import './Page.css';

function Page({
  children,
  className,
  renderWithOverFlow,
}: PropsWithChildren<{
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  renderWithOverFlow?: (overflow: boolean) => ReactNode;
}>) {
  const { ref: innerRef, height: innerHeight } = useResizeDetector();
  const { ref: outerRef, height: outerHeight } = useResizeDetector();

  const overflow = useMemo(
    () =>
      innerHeight !== undefined &&
      outerHeight !== undefined &&
      innerHeight > outerHeight,
    [innerHeight, outerHeight],
  );

  return (
    <div className="Page">
      <div ref={outerRef} className="Page--overflow-detector-outer">
        <div ref={innerRef} className="Page--overflow-detector-inner">
          <div className={`Page--content ${className ?? ''}`}>
            {children ?? renderWithOverFlow?.(overflow)}
          </div>
        </div>
      </div>
    </div>
  );
}

Page.defaultProps = {
  className: undefined,
  renderWithOverFlow: undefined,
};

export default Page;
