import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';

import './app.css';
import Authenticated from './routes/authenticated/index';
import Unauthenticated from './routes/unauthenticated/index';
import useBackendClient from './useBackendClient';
import LoadSession from './routes/unauthenticated/loadsession';

const theme = createTheme();

function App() {
  const client = useBackendClient();
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="*" element={<LoadSession />} />
          <Route path="login/*" element={<Unauthenticated />} />
          <Route path="authenticated/*" element={<Authenticated />} />
        </Routes>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
