import React, { useMemo } from 'react';

import DownArrow from '../components/DownArrow';
import MultiPage from '../components/MultiPage';
import NoPrint from '../components/NoPrint';
import '../components/Typography.css';
import './p6.css';

const transformContent = (content: string) => {
  const regex = /^(.*:)?(.*)$/gm;
  const matches = Array.from(content.replace(/\r\n/g, '\n').matchAll(regex));
  return matches.map(([row, key, value], idx) => {
    if (!key) {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <pre key={`${idx}`} className="P6--info-freetext">
          {row || ' '}
        </pre>
      );
    }

    return (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={`${idx}`}
        className="P6--info-content-container"
      >
        <span className="P6--info-key">
          {key} <span className="P6--info-value">{value}</span>
        </span>
      </div>
    );
  });
};

function P6({
  föreningsnamn,
  föreningsinfo,
  harFörening,
}: {
  harFörening: boolean;
  föreningsnamn: string;
  föreningsinfo: string;
}) {
  const content = useMemo(
    () => transformContent(föreningsinfo),
    [föreningsinfo],
  );

  if (!harFörening) {
    return <NoPrint />;
  }

  return (
    <MultiPage className="P6" renderMoreMarker={() => <DownArrow />}>
      <div className="Typography--header">Förening</div>
      <div className="Typography--title">{föreningsnamn}</div>
      {content}
    </MultiPage>
  );
}

export default P6;
