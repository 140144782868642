import { Avatar, Box, Container, CssBaseline } from '@mui/material';
import React, { useEffect } from 'react';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';

import useSession from '../../useSession';

export default function LoadSession() {
  const { initialLoadDone, userId } = useSession();

  useEffect(() => {
    if (initialLoadDone && userId) {
      window.location.href = '/authenticated';
    }

    if (initialLoadDone && !userId) {
      window.location.href = '/login';
    }
  }, [initialLoadDone, userId]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <HourglassTopOutlinedIcon />
        </Avatar>
        Laddar sessionen...
      </Box>
    </Container>
  );
}
