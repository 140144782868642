import React, { useMemo } from 'react';

import MultiPage from '../components/MultiPage';
import DownArrow from '../components/DownArrow';

import './p5.css';
import '../components/Typography.css';

const transformContent = (parentKey: string | number, content: string) => {
  const stycken = Array.from(
    content.replace(/\r\n/g, '\n').matchAll(/^(.*)$/gm),
  ).map(match => match[0]);

  return stycken.map((stycke, idx, contents) => (
    <div
      // eslint-disable-next-line react/no-array-index-key
      key={`${parentKey}-${idx}`}
      className={`Typography--content ${
        contents.length !== idx + 1 ? 'nomargin' : ''
      }`}
    >
      {stycke || ' '}
    </div>
  ));
};

function P5({
  beskrivning,
  data,
}: {
  beskrivning: string;
  data: { titel: string; innehåll: string }[];
}) {
  const content = useMemo(
    () =>
      data.flatMap(({ titel, innehåll }, idx) => [
        // eslint-disable-next-line react/no-array-index-key
        <div key={idx} className="Typography--title">
          {titel}
        </div>,
        ...transformContent(idx, innehåll ?? ''),
      ]),
    [data],
  );
  return (
    <MultiPage className="P5" renderMoreMarker={() => <DownArrow />}>
      <div className="Typography--header">Beskrivning</div>
      {transformContent('beskrivning', beskrivning)}
      {content}
    </MultiPage>
  );
}

export default P5;
